<template>
<v-app>
  <contact-form
    :name="name"
    :authentication-token="authenticationToken"
    :required-fields="requiredFields"
    buttonWrapperClass="text-center">
      <contact-fields :exclude="exclude"></contact-fields>
      <template slot="disclaimer">
      Ich willige ein, dass die Lass Immobilien GmbH meine Daten zum Zwecke der Bearbeitung meiner Anfrage verarbeitet und Kontakt zu mir bzw. dem Eigentümer aufnimmt.
      Diese Einwilligung kann ich jederzeit ohne Angaben von Gründen widerrufen.
      Ich bestätige außerdem, dass der Eigentümer / die Eigentümer mit der Kontaktaufnahme durch die Lass Immobilien GmbH einverstanden sind.
    </template>
  </contact-form>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFieldsTipp";

export default {
  name: "TippApp",

  components: {
    ContactForm,
    ContactFields
  },

  data() {
    return {
      name: "tipp"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    authenticationToken() {
      if (this.$store && this.$store.state) {
        return this.$store.state.auth.authenticationToken;
      }

      return null;
    },

    loggedIn() {
      if (this.$store && this.$store.state) {
        return this.$store.state.user.loggedIn;
      }

      return false;
    },

    exclude() {
      let ignoreFields = [];
      if (this.loggedIn) {
        ignoreFields = ["email"];
      }

      let el = [];
      if (this.$root.exclude) {
        el = this.root.exclude;
      }

      return ignoreFields.concat(el);
    }
  }
};
</script>
