<template>
<v-tabs
  v-model="tab"
  class="elevation-0 immo-expose__tabs">
  <v-tabs-slider></v-tabs-slider>

  <v-tab
    v-for="i in tabs"
    :key="i.name"
    :href="`#t${i.name}`">
      {{ i.title }}
  </v-tab>

  <v-tab-item
    v-for="i in tabs"
    :key="i.name"
    :value="'t' + i.name">

    <v-card :outlined="true">
      <v-card-text>
        <div>{{ i.value }}</div>
      </v-card-text>
    </v-card>

  </v-tab-item>
</v-tabs>
</template>

<script>
export default {
  name: "ExposeTab",

  data() {
    return {
      tab: null
    }
  },

  computed: {
    tabs() {
      return this.$root.tabs;
    }
  }
}
</script>
