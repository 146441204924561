<template>
<div class="row">
  <div class="col-24 col-lg-12">
    <p class="h3">Ihre Daten</p>
    <div class="row row-small" v-if="exclude.indexOf('person') == -1">
      <div class="col-md-8">
        <contact-select name="salutation" :label="$t('Anrede')" group="contact" :values="$t('Frau,Herr')" :placeholder="$t('Anrede')"></contact-select>
      </div>
      <div class="col-md-8">
        <contact-input name="firstname" :label="$t('Vorname')" group="contact"></contact-input>
      </div>
      <div class="col-md-8">
        <contact-input name="lastname" :label="$t('Nachname')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small" v-if="exclude.indexOf('phone_email') == -1">
      <div class="col-12">
        <contact-input name="phone" :label="$t('Telefon')" group="contact"></contact-input>
      </div>
      <div class="col-12" v-if="exclude.indexOf('email') == -1">
        <contact-input name="email" :label="$t('E-Mail')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-24 col-md-10">
        <contact-input name="street" :label="$t('Straße / Nr.')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="zip" :label="$t('PLZ')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="city" :label="$t('Ort')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-24 messagealign">
        <contact-input textarea :label="$t('Nachricht')" name="message" group="contact"></contact-input>
      </div>
    </div>
  </div>

  <div class="col-24 col-lg-12">
    <p class="h3">Ihr Immobilientipp</p>
    <div class="row row-small" v-if="exclude.indexOf('person') == -1">
      <div class="col-md-12">
        <contact-input name="Objektart" :label="$t('Objektart')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-24 col-md-10">
        <contact-input name="Straße Objekt" :label="$t('Straße / Nr.')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="PLZ Objekt" :label="$t('PLZ')" group="contact"></contact-input>
      </div>
      <div class="col-12 col-md-7">
        <contact-input name="Ort Objekt" :label="$t('Ort')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-md-12">
        <contact-input name="Name des Eigentümers" :label="$t('Name des Eigentümers')" group="contact"></contact-input>
      </div>
      <div class="col-md-12">
        <contact-input name="Anschrift falls abweichend" :label="$t('Anschrift (falls abweichend)')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row row-small">
      <div class="col-12">
        <contact-input name="Telefon des Eigentümers" :label="$t('Telefon des Eigentümers')" group="contact"></contact-input>
      </div>
      <div class="col-12">
        <contact-input name="E-Mail des Eigentümers" :label="$t('E-Mail des Eigentümers')" group="contact"></contact-input>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-24">
        <contact-input  textarea :label="$t('Weitere Angaben zur Immobilie oder zum Eigentümer')" name="Weitere Angaben" group="contact"></contact-input>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";

export default {
  name: "ContactFieldsTipp",

  components: {
    ContactInput,
    ContactSelect
  },

  props: {
    include: {
      type: Array,
      default: () => []
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      contactType: []
    };
  },

  computed: {
    fields() {
      if (!this.include) {
        return [];
      }

      return [...this.include];
    }
  },

  mounted() {
    this.$root.contactForm.registerField(this);
  },

  beforeDestroy() {
    this.$root.contactForm.unregisterField(this);
  },

  methods: {
    mapValue() {
      this.$root.contactForm.contact.contactType = this.contactType;
    }
  }
};
</script>
